export interface EnvData {
    env: 'dev' | 'int' | 'prd';
    databaseVersion: number;
    customerId: string;
    customerLogo: string;
    oauth2ClientID: string;
    auth0Domain: string;
    auth0Audience: string;
    scope: string;
    alertingBaseUrl: string;
    adminBaseUrl: string;
    indabaBaseUrl: string;
    indametaBaseUrl: string;
    umsBaseUrl: string;
    desktopUrl: string;
    formsFillUrl: string;
    embbedDashboard: string;
}

export interface EnvSettings {
    env: 'dev' | 'int' | 'prd';
    customerId: string;
    oauth2ClientId: string;
    customerLogo: string;
    scope: string;
    databaseVersion: number;
}

export function generateEnv(envSettings: EnvSettings): EnvData {
    const envPrefix = envSettings.env === 'prd' ? '' : `${envSettings.env}.`;

    return {
        env: envSettings.env,
        //Increment when database structure changes
        databaseVersion: 2,
        customerId: envSettings.customerId,
        customerLogo: envSettings.customerLogo,
        oauth2ClientID: envSettings.oauth2ClientId,
        auth0Domain: 'io-base.eu.auth0.com',
        auth0Audience: `https://api.io-base.com/indaba-${envSettings.customerId}`,
        scope: envSettings.scope,
        adminBaseUrl: `https://${envPrefix}${envSettings.customerId}.admin.api.indasuite.io-base.com/`,
        alertingBaseUrl: `https://${envPrefix}${envSettings.customerId}.alerting.api.indasuite.io-base.com/v1/`,
        indabaBaseUrl: `https://${envPrefix}${envSettings.customerId}.indaba.api.indasuite.io-base.com/`,
        indametaBaseUrl: `https://${envPrefix}${envSettings.customerId}.meta.api.indasuite.io-base.com/v2/`,
        umsBaseUrl: `https://${envPrefix}${envSettings.customerId}.ums.api.indasuite.io-base.com/`,
        desktopUrl: `https://${envPrefix}${envSettings.customerId}.portal.indasuite.io-base.com/app/home`,
        formsFillUrl: `https://${envPrefix}${envSettings.customerId}.mobile.indasuite.io-base.com/forms/`,
        embbedDashboard: `https://${envPrefix}${envSettings.customerId}.explorer.indasuite.io-base.com/embbed/report/show/`
    };
}
