import { AlertHistory, AlertReport, AlertReportUpsert, AlertType, ReportsModalDisplay } from '@models/alerting-models';
import { AlertFilters } from '@models/alerting-models/alert-filters';
import { InfoPagination } from '@models/api-models';
import { DateTimePeriod } from '@models/dateTimePeriod';
import { createAction, props } from '@ngrx/store';
import { DateTime } from 'luxon';

export const setAlertType = createAction('[Alerting] Set alert type', props<{ alertType: AlertType }>());

export const setDateTimePeriod = createAction('[Alerting] Set alert datetime period', props<{ dateTimePeriod: DateTimePeriod }>());

export const isUserOnCall = createAction('[Alerting] Is user on-call');
export const isUserOnCallSuccess = createAction('[Alerting] Is user on-call Success', props<{ isOnCall: boolean }>());

export const getAlertsHistory = createAction('[Alerting] Get alerts history');
export const getAlertsHistorySuccess = createAction('[Alerting] Get alerts history Success', props<{ alertsHistory: AlertHistory[], pagination: InfoPagination }>());
export const getAlertsHistoryError = createAction('[Alerting] Get alerts history Error');

export const getOnCallAlertsHistory = createAction('[Alerting] Get on-call alerts history');
export const getOnCallAlertsHistorySuccess = createAction('[Alerting] Get on-call alerts history Success', props<{ onCallAlertsHistory: AlertHistory[], pagination: InfoPagination }>());
export const getOnCallAlertsHistoryError = createAction('[Alerting] Get on-call alerts history Error');

export const getNextHistoryDispatcher = createAction('[Alerting] Get next history dispatcher');

export const getNextAlertsHistory = createAction('[Alerting] Get next alerts history');
export const getNextAlertsHistorySuccess = createAction('[Alerting] Get next alerts history Success', props<{ alertsHistory: AlertHistory[], pagination: InfoPagination }>());
export const getNextAlertsHistoryError = createAction('[Alerting] Get next alerts history Error');

export const getNextOnCallAlertsHistory = createAction('[Alerting] Get next on-call alerts history');
export const getNextOnCallAlertsHistorySuccess = createAction('[Alerting] Get next on-call alerts history Success', props<{ onCallAlertsHistory: AlertHistory[], pagination: InfoPagination }>());
export const getNextOnCallAlertsHistoryError = createAction('[Alerting] Get next on-call alerts history Error');

export const openReportsModal = createAction('[Alerting] Reports modal Open', props<{ alertId: string, dateEvent: DateTime, display: ReportsModalDisplay }>());

export const getAlertHistoryReports = createAction('[Alerting] Get alert history reports', props<{ alertId: string, dateEvent: DateTime, }>());
export const getAlertHistoryReportsSuccess = createAction('[Alerting] Get alert history reports Success', props<{ alertId: string, dateEvent: DateTime, reports: AlertReport[] }>());
export const getAlertHistoryReportsError = createAction('[Alerting] Get alert history reports Error');

export const addReport = createAction('[Alerting] Add report', props<{ alertId: string, dateEvent: DateTime, report: AlertReportUpsert }>());
export const addReportError = createAction('[Alerting] Add report Error');

export const editReport = createAction('[Alerting] Edit report', props<{ alertId: string, dateEvent: DateTime, reportId: string, report: AlertReportUpsert }>());
export const editReportError = createAction('[Alerting] Edit report Error');

export const deleteReport = createAction('[Alerting] Delete report', props<{ alertId: string, dateEvent: DateTime, report: AlertReport }>());
export const deleteReportError = createAction('[Alerting] Delete report Error');

export const setAlertFilters = createAction('[Alerting] Set alert filters', props<{ filters: AlertFilters }>());
