import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { State, alertingFeatureKey } from './alerting.reducer';
import { DateTimePeriod } from '@models/dateTimePeriod';
import { DateTime } from 'luxon';
import { AlertHistory, AlertReport, AlertType } from '@models/alerting-models';

const selectAlertingState = createFeatureSelector<State>(alertingFeatureKey);

// Raw selectors
export const selectAlertType = createSelector(selectAlertingState, (state: State) => state.alertType);

export const selectDateTimePeriodString = createSelector(selectAlertingState, (state: State) => state.dateTimePeriod);
export const selectDateTimePeriod = createSelector<object, State, DateTimePeriod>(selectAlertingState, (state: State) => ({
    period: state.dateTimePeriod.period,
    start: DateTime.fromISO(state.dateTimePeriod.start),
    end: DateTime.fromISO(state.dateTimePeriod.end)
}));

export const selectIsUserOnCall = createSelector(selectAlertingState, (state: State) => state.isOnCall);

export const selectAlertFilters = createSelector(selectAlertingState, (state: State) => state.filters);

export const selectAlertHistory = createSelector(selectAlertingState, (state: State) => {
    return state.alertsHistory;
});

export const selectOnCallAlertsHistory = createSelector(selectAlertingState, (state: State) => {
    return state.onCallAlertsHistory;
});


export const selectAlertsHistoryPagination = createSelector(selectAlertingState, (state: State) => state.alertsPagination);
export const selectAlertsHasMore = createSelector(selectAlertingState, (state: State) => !(state.alertsPagination?.isComplete ?? true));

export const selectOnCallAlertsHistoryPagination = createSelector(selectAlertingState, (state: State) => state.onCallPagination);
export const selectOnCallHasMore = createSelector(selectAlertingState, (state: State) => !(state.onCallPagination?.isComplete ?? true));
export const selectIsFilterActivated = createSelector(selectAlertingState, (state: State) => state.isFilterActivated);

// Combined selectors

export const selectHistoryById = (alertId: string, dateEvent: DateTime, alertType: AlertType): MemoizedSelector<object, AlertHistory, (s1: AlertHistory[]) => AlertHistory> =>
    createSelector(
        alertType === 'Default' ? selectAlertHistory : selectOnCallAlertsHistory,
        (history) => history.find(h => h.alertId === alertId && h.dateEvent === dateEvent)
    );

export const selectHistoryReport = (alertId: string, dateEvent: DateTime, alertType: AlertType): MemoizedSelector<object, AlertReport[], (s1: AlertHistory) => AlertReport[]> =>
    createSelector(
        selectHistoryById(alertId, dateEvent, alertType),
        (history) => history?.reports ?? []
    );
